import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import * as React from "react";
import Layout from "../../components/layout/layout";
import { MobileFrameVideo } from "../../components/mobile-frame-video/mobile-frame-video";
import * as styles from "./project-page.module.scss";

const IconLink = () => (
    <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.iconLink}
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.35352 8.06067L10.5355 11.2426L12.6568 0.635986L2.05023 2.75732L5.23221 5.93933L0.989563 10.182L3.11087 12.3033L7.35352 8.06067Z"
            fill="#EEC6CA"
        />
    </svg>
);

const IconTriangleBack = (props) => (
    <svg
        width="12"
        height="16"
        viewBox="0 0 12 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M12 0V16L0 8L12 0Z" fill="#1F181C" />
    </svg>
);

const BackButton = () => {
    return (
        <Link to="/" className={styles.backButton}>
            <IconTriangleBack /> <h5>Back to all projects</h5>
        </Link>
    );
};

const Project = (props) => {
    const { contentfulProject: project } = props.data;

    const plainTextIntro = documentToPlainTextString(
        JSON.parse(project.introParagraph.raw)
    );

    return (
        <Layout
            pageTitle={`Dasha Morris - ${project.title}`}
            pageMeta={plainTextIntro}
            pageKeywords={project.title + "," + project.type.join(",")}
        >
            <BackButton />
            <h1>{project.title}</h1>
            <h3>{project.type.join(", ")}</h3>
            <div className={styles.introBlock}>
                <div className={styles.metaBlock}>
                    <div className={styles.metaBlockItem}>
                        <h5>Client</h5>
                        <p>
                            {project.clientUrl ? (
                                <a
                                    target="__blank_"
                                    noreferrer
                                    href={project.clientUrl}
                                >
                                    {project.client}
                                    <IconLink />
                                </a>
                            ) : (
                                project.client
                            )}
                        </p>
                    </div>
                    <div className={styles.metaBlockItem}>
                        <h5>Project Link</h5>
                        <p>
                            <a target="__blank_" noreferrer href={project.link}>
                                {project.linkText}
                                <IconLink />
                            </a>
                        </p>
                    </div>
                </div>
                <div className={styles.introParagraph}>
                    {renderRichText(project.introParagraph)}
                </div>
            </div>
            <div className={styles.introImage}>
                <GatsbyImage image={getImage(project.introImage)} />
            </div>
            <div className={styles.introImageMobile}>
                <GatsbyImage
                    image={getImage(
                        project.introImageMobile || project.introImage
                    )}
                />
            </div>
            <div className={styles.sections}>
                {project.sections.map((section) => (
                    <ProjectSection key={section.id} section={section} />
                ))}
            </div>
        </Layout>
    );
};

const richTextOptions = {
    renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
            switch (node.data.target.__typename) {
                case "ContentfulCaptionedImage": {
                    return (
                        <CaptionedImage
                            image={node.data.target.image}
                            richText={node.data.target.captionText}
                            captionColour={node.data.target.colour}
                        />
                    );
                }
                case "ContentfulMobileFrameVideo": {
                    return (
                        <MobileFrameVideo
                            title={node.data.target.title}
                            videoUrl={node.data.target.video.file.url}
                            colour={node.data.target.colour}
                        />
                    );
                }
            }
        },
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            return (
                <CaptionedImage
                    image={node.data.target}
                    caption={node.data.target.description}
                />
            );
        },
    },
};

const CaptionedImage = ({ image, caption, richText, captionColour }) => {
    const textContents = richText ? (
        renderRichText(richText)
    ) : caption ? (
        <p>{caption}</p>
    ) : null;

    return (
        <div className={styles.imageWrapper}>
            <GatsbyImage image={getImage(image)} />
            {textContents && (
                <div
                    className={styles.imageCaption}
                    data-colour={captionColour}
                >
                    {textContents}
                </div>
            )}
        </div>
    );
};

const ProjectSection = ({ section }) => {
    const { heading, type, content } = section;

    return (
        <section className={styles.section} data-type={type || "none"}>
            {heading && <h4>{heading}</h4>}
            {renderRichText(content, richTextOptions)}
        </section>
    );
};

export const query = graphql`
    query ($id: String) {
        contentfulProject(id: { eq: $id }) {
            client
            clientUrl
            title
            type
            link
            linkText
            introParagraph {
                raw
            }
            introImage {
                gatsbyImageData(placeholder: NONE, formats: [AUTO])
            }
            introImageMobile {
                gatsbyImageData(placeholder: NONE, formats: [AUTO])
            }
            sections {
                id
                heading
                type
                content {
                    raw
                    references {
                        ... on ContentfulCaptionedImage {
                            contentful_id
                            __typename
                            colour
                            image {
                                gatsbyImageData(
                                    placeholder: NONE
                                    formats: [AUTO]
                                )
                            }
                            captionText {
                                raw
                            }
                        }
                        ... on ContentfulMobileFrameVideo {
                            contentful_id
                            __typename
                            colour
                            title
                            id
                            video {
                                file {
                                    url
                                }
                            }
                        }
                        ... on ContentfulAsset {
                            contentful_id
                            __typename
                            description
                            gatsbyImageData(placeholder: NONE, formats: [AUTO])
                        }
                    }
                }
            }
        }
    }
`;

export default Project;
