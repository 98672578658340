import React from "react";
import frameSvg from "./frame.svg";
import * as styles from "./mobile-frame-video.module.scss";

export const MobileFrameVideo = ({ title, videoUrl, colour }) => {
    return (
        <div className={styles.section} data-colour={colour}>
            {title && <h4>{title}</h4>}
            <div className={styles.frameWrapper}>
                <video
                    className={styles.video}
                    src={videoUrl}
                    autoPlay
                    loop
                    muted
                />
                <img className={styles.frameImg} src={frameSvg} />
            </div>
        </div>
    );
};
