// extracted by mini-css-extract-plugin
export var backButton = "project-page-module--backButton--24vT8";
export var introBlock = "project-page-module--introBlock--19ZSC";
export var metaBlock = "project-page-module--metaBlock--AOKk9";
export var metaBlockItem = "project-page-module--metaBlockItem--lRXae";
export var iconLink = "project-page-module--iconLink--2B02e";
export var introParagraph = "project-page-module--introParagraph--gv7gk";
export var introImage = "project-page-module--introImage--358uF";
export var introImageMobile = "project-page-module--introImageMobile--3yW2j";
export var sections = "project-page-module--sections--7LlkO";
export var section = "project-page-module--section--1uZbF";
export var sectionBg = "project-page-module--sectionBg--2bzub";
export var imageWrapper = "project-page-module--imageWrapper--2c0Ch";
export var imageCaption = "project-page-module--imageCaption--1iwA9";